import { useState } from 'react';
import './App.css';

function App() {
  const [buttonText, setButtonText] = useState('100.000₽');
  
  const handleClick = () => {
    setButtonText('SOLD OUT');
  };

  return (
    <div className="App">
      <div className="tag flex-center">#ШпакМетод_Offline</div>
      <div className="date">18-20 октября</div>
      <div onClick={handleClick} className="btn-price flex-center">{buttonText}</div>
    </div>
  );
}

export default App;
